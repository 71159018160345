import Cookies from 'universal-cookie';

export const PODBEAN_AUTH = "PODBEAN_AUTH";

const cookies = new Cookies();

export const setCookie = (key, value) => {
    cookies.set(key, value, { path: '/' });
};

export const removeToken = (key) => {
    cookies.remove(key);
};

export const getCookie = (key) => {
    return cookies.get(key);
};

export const getPodBeanAuth = () => {
    return getCookie(PODBEAN_AUTH);
};

export const setPodBeanAuth = (value) => {
    return setCookie(PODBEAN_AUTH, value);
};