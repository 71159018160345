import Axios from "axios";
import {
  getPath,
  bodyHeader,
  $observable,
} from "../../common/libs/AxiosObservable";

export const getServers = () => {
  return $observable(Axios.get(getPath("api/v1/admin/servers")));
};

export const getServer = (id) => {
  return $observable(Axios.get(getPath(`api/v1/admin/servers/${id}`)));
};

export const deleteServer = (id) => {
  return $observable(Axios.delete(getPath(`api/v1/admin/servers/${id}`)));
};

export const addServer = (obj) => {
  return $observable(
    Axios.post(getPath(`api/v1/admin/servers`), JSON.stringify(obj), {
      headers: bodyHeader,
    })
  );
};

export const modifyServer = (obj) => {
  return $observable(
    Axios.patch(getPath(`api/v1/admin/servers`), JSON.stringify(obj), {
      headers: bodyHeader,
    })
  );
};
