import { Observable } from "rxjs";
const baseURL = 'https://api.system.cmate.vn';

export default class AxiosObservable {
    result = null
    constructor(result) {
        this.result = result;
    }

    $observable() {
        return new Observable((subscriber) => {
            if (this.result) {
                this.result.then(response => {
                    subscriber.next(response);
                }).catch(error => {
                    subscriber.error(error);
                    console.log(error)
                }).then(() => {
                    subscriber.complete();
                });
            }
        });
    }
}

export function $observable(axiosChain) {
    return new Observable((subscriber) => {
        axiosChain.then(response => {
            subscriber.next(response);
        }).catch(error => {
            subscriber.error(error);
            console.log(error)
        }).then(() => {
            subscriber.complete();
        });
    });
}

export const getPath = (path) => {
    return `${baseURL}/${path}`;
};

export const bodyHeader = {
    "Content-Type": "application/json"
};

export const mapForm = (form) => {
    const params = new URLSearchParams();
    for (let key in form) {
        if (form.hasOwnProperty(key)) {
            params.append(key, form[key]);
        }
    }
    return params;
}