import Axios from "axios";
import {
  getPath,
  bodyHeader,
  $observable,
} from "../../common/libs/AxiosObservable";

export const getVPSs = () => {
  return $observable(Axios.get(getPath("api/v1/admin/vms")));
};

export const getVPSsByServerId = (id) => {
  if (id === "all") {
    return getVPSs();
  }
  return $observable(
    Axios.get(getPath(`api/v1/admin/vms?$filter=serverID eq ${id}`))
  );
};

export const getVPS = (id) => {
  return $observable(Axios.get(getPath(`api/v1/admin/vms/${id}`)));
};

export const deleteVPS = (id) => {
  return $observable(Axios.delete(getPath(`api/v1/admin/vms/${id}`)));
};

export const addVPS = (obj) => {
  return $observable(
    Axios.post(getPath(`api/v1/admin/vms`), JSON.stringify(obj), {
      headers: bodyHeader,
    })
  );
};

export const modifyVPS = (obj) => {
  return $observable(
    Axios.patch(getPath(`api/v1/admin/vms`), JSON.stringify(obj), {
      headers: bodyHeader,
    })
  );
};
