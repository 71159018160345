import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { modifyNetwork, addNetwork } from "../../domain/apis/networkService";
import _ from "lodash";
import { useEffect } from "react";

function NetworkModifyComponent({ show, handleClose, data }) {
  /*
  {
    "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "name": "string",
    "description": "string",
    "bandwidth": 0,
    "pppoeUsername": "string",
    "pppoePassword": "string",
    "wanip": "string",
    "status": "string",
    "created": "2024-09-08T03:28:27.043Z",
    "modified": "2024-09-08T03:28:27.043Z",
    "createdBy": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "modifiedBy": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
  } */

  var formUI = {};
  useEffect(() => {
    if (!data) {
      data = {};
      formUI = {};
    } else {
      formUI = data;
    }
  }, [data]);

  const handleUpdateForm = (key, value) => {
    formUI[key] = value;
    console.log(formUI);
  };

  const onModify = () => {
    if (data) {
      modifyNetwork(formUI).subscribe({
        next: ({ data }) => {
          console.log(data);
          handleClose(true);
        },
      });
      return;
    }
    addNetwork(formUI).subscribe({
      next: ({ data }) => {
        console.log(data);
        handleClose(true);
      },
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => handleClose()}
        centered
        style={{ zIndex: 10000 }}
      >
        <Modal.Header>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            {data && <Modal.Title>Chỉnh sửa Network</Modal.Title>}
            {!data && <Modal.Title>Thêm Network</Modal.Title>}
          </div>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel required htmlFor="outlined-adornment-name">
                  Tên network
                </InputLabel>
                <OutlinedInput
                  defaultValue={_.get(data, "name")}
                  onChange={({ target }) => {
                    let { value } = target;
                    handleUpdateForm("name", value);
                  }}
                  required
                  id="outlined-adornment-name"
                  label="Tên network"
                />
              </FormControl>
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-discription">
                  Mô tả
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-discription"
                  label="Mô tả"
                  defaultValue={_.get(data, "discription")}
                  onChange={({ target }) => {
                    let { value } = target;
                    handleUpdateForm("description", value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-bandwith">
                  Băng thông
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-bandwith"
                  label="Băng thông"
                  type="number"
                  startAdornment={
                    <InputAdornment position="start">Mbps</InputAdornment>
                  }
                  defaultValue={_.get(data, "bandwidth")}
                  onChange={({ target }) => {
                    let { value } = target;
                    handleUpdateForm("bandwidth", value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-pppoeUsername">
                  PPPOE username
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-pppoeUsername"
                  label="PPPOE username"
                  defaultValue={_.get(data, "pppoeUsername")}
                  onChange={({ target }) => {
                    let { value } = target;
                    handleUpdateForm("pppoeUsername", value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-pppoeUsername">
                  PPPOE password
                </InputLabel>
                <OutlinedInput
                  defaultValue={_.get(data, "pppoePassword")}
                  id="outlined-adornment-pppoePassword"
                  label="PPPOE password"
                  onChange={({ target }) => {
                    let { value } = target;
                    handleUpdateForm("pppoePassword", value);
                  }}
                />
              </FormControl>
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-wanip">
                  WAN IP
                </InputLabel>
                <OutlinedInput
                  type="number"
                  id="outlined-adornment-wanip"
                  label="WAN IP"
                  defaultValue={_.get(data, "wanip")}
                  onChange={({ target }) => {
                    let { value } = target;
                    handleUpdateForm("wanip", value);
                  }}
                />
              </FormControl>
            </Box>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Hủy
          </Button>
          <Button variant="primary" onClick={() => onModify()}>
            Lưu
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NetworkModifyComponent;
