import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import categoryIcon from "assets/images/icons/category.png";

export default function data({ model, deleteEvent, editEvent }) {
  model = model ?? [];

  const Overall = ({ image, name, id }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{id}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const RowItem = ({ value }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography variant="caption">{value} Mbps</MDTypography>
    </MDBox>
  );

  const rows = [];

  model.forEach((item) => {
    rows.push({
      overall: (
        <Overall image={categoryIcon} name={item.name} id={item.id ?? 0} />
      ),
      description: <RowItem value={item.description} />,
      branch: <RowItem value={item.branch} />,
      specification: <RowItem value={item.specification} />,
      configuration: <RowItem value={`${item.core} CPU - ${item.ram} GB - ${item.storage} GB`} />,
      ip: <RowItem value={item.ip} />,
      function: (
        <MDBox ml={-1}>
          <div
            code={"btnEdit" + item.id}
            onClick={() => editEvent(`${item.id}`)}
          >
            <MDBadge
              badgeContent="Sửa"
              color="success"
              variant="gradient"
              size="sm"
            />
          </div>
          <div
            code={"btnDelete" + item.id}
            onClick={() => {
              deleteEvent(`${item.id}`);
            }}
          >
            <MDBadge
              badgeContent="Xóa"
              color="warning"
              variant="gradient"
              size="sm"
            />
          </div>
        </MDBox>
      ),
    });
  });

  return {
    columns: [
      { Header: "Tên", accessor: "overall", width: "45%", align: "left" },
      { Header: "Mô tả", accessor: "description", align: "left" },
      { Header: "Hãng sản phẩm", accessor: "branch", align: "left" },
      { Header: "Thông số kỹ thuật", accessor: "specification", align: "left" },
      { Header: "Cấu hình", accessor: "configuration", align: "left" },
      { Header: "IP", accessor: "ip", align: "left" },
      { Header: "Thao tác", accessor: "function", align: "center" },
    ],
    rows: rows,
  };
}
