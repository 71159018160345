// Material Dashboard 2 React layouts
import Dashboard from "./layouts/dashboard";
import VPS from "./layouts/vps";
import Servers from "./layouts/servers";
import Networks from "layouts/networks";
import SignIn from "./layouts/authentication/sign-in";
// import SignUp from "./layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "VPS",
    key: "vps",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/vps",
    component: <VPS />,
  },
  {
    type: "collapse",
    name: "Server",
    key: "servers",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/servers",
    component: <Servers />,
  },
  {
    type: "collapse",
    name: "Network",
    key: "networks",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/networks",
    component: <Networks />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  }
];

export default routes;
