import Axios from "axios";
import {
  getPath,
  bodyHeader,
  $observable,
} from "../../common/libs/AxiosObservable";

export const getNetworks = () => {
  return $observable(Axios.get(getPath("api/v1/admin/networks")));
};

export const getNetwork = (id) => {
  return $observable(Axios.get(getPath(`api/v1/admin/networks/${id}`)));
};

export const deleteNetwork = (id) => {
  return $observable(Axios.delete(getPath(`api/v1/admin/networks/${id}`)));
};

export const addNetwork = (obj) => {
  return $observable(
    Axios.post(getPath(`api/v1/admin/networks`), JSON.stringify(obj), {
      headers: bodyHeader,
    })
  );
};

export const modifyNetwork = (obj) => {
  return $observable(
    Axios.patch(getPath(`api/v1/admin/networks`), JSON.stringify(obj), {
      headers: bodyHeader,
    })
  );
};
