import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./app";
import 'bootstrap/dist/css/bootstrap.min.css';

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "./context";
import { initDefault } from "common/libs/AxiosDefaultConfig";

initDefault();

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <App />
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
