import axios from 'axios';
import { $observable, getPath, bodyHeader } from '../../common/libs/AxiosObservable';

export const login = ({
    username,
    password
}) => {
    let params = JSON.stringify({
        username,
        password
    });

    return $observable(axios.post(getPath('api/v1/auth/login'), params, { headers: bodyHeader }));
};

export const requestRefreshToken = ({
    accessToken,
    refreshToken
}) => {
    let params = JSON.stringify({
        accessToken,
        refreshToken
    });

    return axios.post(getPath('api/v1/auth/token-refresh'), params, { headers: bodyHeader });
};