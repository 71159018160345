import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import _ from "lodash";

const FormUIItem = ({
    code,
    label,
    initValue,
    required,
    type,
    unit,
    handleUpdate }) => {
    if (!code || !label) {
        return <></>;
    }
    return (<><div style={{ height: unit ? 4 : 0 }} />
        <FormControl fullWidth sx={{ m: 1 }}>
            <InputLabel required={required === true} htmlFor={`outlined-adornment-${code}`}>
                {label}
            </InputLabel>
            <OutlinedInput
                defaultValue={initValue}
                onChange={({ target }) => {
                    let { value } = target;
                    handleUpdate(code, value);
                }}
                startAdornment={
                    unit && <InputAdornment position="start">{unit}</InputAdornment>
                }
                type={type}
                required={required === true}
                id={`outlined-adornment-${code}`}
                label={label}
            />
        </FormControl>
    </>);
}

export default FormUIItem;