import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import categoryIcon from "assets/images/icons/category.png";

export default function data({ model, deleteEvent, editEvent }) {
  model = model ?? [];

  const Overall = ({ image, name, id }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{id}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Description = ({ description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const Bandwidth = ({ value }) => (
    <MDBox lineHeight={1} textAlign="right">
      <MDTypography variant="caption">{value} Mbps</MDTypography>
    </MDBox>
  );

  const rows = [];

  model.forEach((item) => {
    rows.push({
      overall: (
        <Overall image={categoryIcon} name={item.name} id={item.id ?? 0} />
      ),
      description: <Description description={item.description} />,
      bandwidth: <Bandwidth value={item.bandwidth} />,
      function: (
        <MDBox ml={-1}>
          <div
            code={"btnEdit" + item.id}
            onClick={() => editEvent(`${item.id}`)}
          >
            <MDBadge
              badgeContent="Sửa"
              color="success"
              variant="gradient"
              size="sm"
            />
          </div>
          <div
            code={"btnDelete" + item.id}
            onClick={() => {
              deleteEvent(`${item.id}`);
            }}
          >
            <MDBadge
              badgeContent="Xóa"
              color="warning"
              variant="gradient"
              size="sm"
            />
          </div>
        </MDBox>
      ),
    });
  });

  return {
    columns: [
      { Header: "Tên", accessor: "overall", width: "45%", align: "left" },
      { Header: "Mô tả", accessor: "description", align: "left" },
      { Header: "Băng thông", accessor: "bandwidth", align: "left" },
      { Header: "Thao tác", accessor: "function", align: "center" },
    ],
    rows: rows,
  };
}
