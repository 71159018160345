import React from "react";
import Toast from "react-bootstrap/Toast";
import ToastStyle from './style';
import { Grid } from "@mui/material";
import categoryIcon from "assets/images/icons/category.png";

export default function ToastWidget({
    key,
    isShow,
    title,
    message,
    delay,
    onClose
}) {
    if (title === undefined) {
        title = "Thông Báo";
    }

    return (
        <Grid item xs={12}>
            <div style={ToastStyle} key={key}>
                <Toast key={key}
                    id="toast"
                    onClose={() => onClose()}
                    show={isShow}
                    delay={delay ?? 3000}
                    autohide
                >
                    <Toast.Header closeButton={false}>
                        <img style={{ width: 16, height: 16, marginRight: 8 }}
                            src={categoryIcon}
                            className="rounded mr-2"
                            alt=""
                        />
                        <strong className="mr-auto">{title}</strong>
                        {/* <small>bây giờ</small> */}
                    </Toast.Header>
                    <Toast.Body>{message}</Toast.Body>
                </Toast>
            </div>
        </Grid>
    );
};